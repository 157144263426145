import { render, staticRenderFns } from "./questionList.vue?vue&type=template&id=167d9d45&scoped=true"
import script from "./questionList.vue?vue&type=script&lang=js"
export * from "./questionList.vue?vue&type=script&lang=js"
import style0 from "./questionList.vue?vue&type=style&index=0&id=167d9d45&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "167d9d45",
  null
  
)

export default component.exports