<template>
  <div class="record">
    <button
      id="recordingBtn"
      v-if="false"
      type="success"
      @click="getPermission()"
    >
      获取麦克风权限
    </button>

    <br />

    <button
      class="hand_change"
      id="recordingBtn"
      v-if="isVoice == 1"
      type="info"
      @click="startRecorder()"
    >
      开始录音
    </button>

    <button
      id="recordingBtn"
      class="hand_change"

      v-if="isVoice == 2"
      type="info"
      @click="stopRecorder()"
    >
      结束录音
    </button>

    <button
      id="recordingBtn"
      class="hand_change"

      v-if="isVoice == 3"
      type="success"
      @click="playRecorder()"
    >
      播放录音
    </button>

    <button
      id="recordingBtn"
      class="hand_change"

      v-if="false"
      type="error"
      @click="destroyRecorder()"
    >
      销毁录音
    </button>

    <button
      id="recordingBtn"
      class="hand_change"
      v-if="false"
      type="info"
      @click="resumeRecorder()"
    >
      继续录音
    </button>

    <button id="recordingBtn" v-if="false" type="info" @click="pauseRecorder()" class="hand_change"
    >
      暂停录音
    </button>

    <br />

    <button
      id="recordingBtn"
      v-if="isVoice == 4"
      type="success"
      @click="pausePlayRecorder()"
      style="margin-right: 10px"
      class="hand_change"

    >
      暂停录音播放
    </button>

    <button
      id="recordingBtn"
      v-if="isVoice == 5"
      type="success"
      @click="resumePlayRecorder()"
      style="margin-right: 10px"
      class="hand_change"

    >
      恢复录音播放
    </button>

    <button
      id="recordingBtn"
      v-if="isVoice == 4 || isVoice == 5"
      type="success"
      @click="stopPlayRecorder()"
      class="hand_change"

    >
      停止录音播放
    </button>
    <!--
		<br />

		<button type="info" @click="getRecorder()">获取录音信息</button>

		<button type="info" @click="downPCM()">下载PCM</button>

		<button type="info" @click="downWAV()">下载WAV</button>

		<button type="info" @click="getMp3Data()">下载MP3</button>

		<br />
 -->
    <!-- <audio id="audioVoice" :src="audioSrc" controls autoplay></audio> -->
  </div>
</template>


<script>
import Recorder from "js-audio-recorder";

const lamejs = require("lamejs");

let recorder = new Recorder({
  sampleBits: 16, // 采样位数，支持 8 或 16，默认是16

  sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000

  numChannels: 1, // 声道，支持 1 或 2， 默认是1

  // compiling: false,(0.x版本中生效,1.x增加中) // 是否边录边转换，默认是false
});

// 绑定事件-打印的是当前录音数据
// recorder.onprogress = function (params) {
  // console.log('--------------START---------------')
  // console.log('录音时长(秒)', params.duration);
  // this.timeupdate(params.duration);
  // console.log('录音大小(字节)', params.fileSize);
  // console.log('录音音量百分比(%)', params.vol);
  // console.log('当前录音的总数据([DataView, DataView...])', params.data);
  // console.log('--------------END---------------')
// };

export default {
  name: "home",
  destroyed() {
    console.log('子组件已经被销毁');
    // 在这里添加你希望在销毁之后执行的清理操作
    this.stopRecorder();
  },
  data() {
    return {
      audioContext: new (window.AudioContext || window.webkitAudioContext)(),
      recordTime: null,//记录录音时长
      timeout: null,
      playTime: null,//剩余播放时长
      isVoice: 1,
      audioSrc: "",
      answer_time: 40,
      allow_time: 39.9,
      mode: 1, // 模式1=全文2=一句话
      word_start_index: -1,
      word_end_index: -1,
      recordingInterval: null
    };
  },

  methods: {
    changeTime(time){
      this.answer_time = time.answer_time;
      this.allow_time = time.allow_time;
      //console.log(time,this.answer_time,this.allow_time);
    },
    /**
     * 录音的具体操作功能
     * */
    resetIsVoice() {
      if(this.isVoice == 4) {
        recorder.stopPlay();
      }
      this.isVoice = 1;
    },
    /**
     * 更换模式
     */
    changeMode(mode) {
      this.mode = mode;
      console.log('mode',this.mode);
    },
    /**
     * 更新选择单词索引
     */
    changeWordIndex(word_start_index,word_end_index) {
      this.word_start_index = word_start_index;
      this.word_end_index = word_end_index;
      //console.log('word_start_index',this.word_start_index);
      //console.log('word_end_index',this.word_end_index);
    },
    // 开始录音
    startRecorder() {
      let that = this;
      if(that.mode == 2) {
        if(that.word_end_index-that.word_start_index < 20) {
            that.$emit('ErrorMsg2');
            return;
          }
      }
      that.audioContext.resume().then(() => {
        recorder.start().then(() => {
          that.isVoice = 2;
          // 开始事件
          that.$emit("BeginEven");
          recorder.onprogress = function (params) {
            //console.log('当前录音的总数据([DataView, DataView...])', params.data);
            //console.log('录音时长(秒)', params.duration);
            if (params.duration >= that.allow_time) {
              that.timeupdate({duration:params.duration,show_time:that.answer_time});
              that.stopRecorder();
            } else {
              that.timeupdate({duration:params.duration,show_time:params.duration});
            }
          };
        }).catch((error)=>{
          console.log(error);
          that.$emit('ErrorMsg');
        });
      });
    },

    timeupdate(duration){
      //console.log('录音市场改变的事件======================>',duration);
      this.$emit('audioDuration',duration);
    },

    // 继续录音

    resumeRecorder() {
      recorder.resume();
    },

    // 暂停录音

    pauseRecorder() {
      this.$emit("notplay");

      recorder.pause();

    },


    setTimeoutfunc() {
      let that = this
      that.timeout = setTimeout(function () {
        that.stopPlayRecorder()
      }, this.playTime)
    },

    // 结束录音

    async stopRecorder() {
      // 暂停录音
      recorder.stop();
      console.log('recorder.duration',recorder.duration)
      // 更改状态
      this.isVoice = 3;
      // 获取录音数据
      let AudioData = await this.getRecorder();
      console.log(AudioData, '录音数据')
      // console.log('当前的录音时长', recorder.getPlayTime(3))
      // this.playTime = recorder.getPlayTime().toFixed(3)*1000
      // this.recordTime =  this.playTime
      // console.log('当前的录音时长', this.playTime)
      this.$emit("GetAudioData", AudioData);
      let self = this;
      this.$emit("notplay");
    },
    // 重置录音
    stopReset() {
      // 暂停录音
      recorder.stop();
      // 更改状态
      this.isVoice = 1;
      this.destroyRecorder();
    },
    // 录音播放
    playRecorder() {
      clearTimeout(this.timeout)
      this.playTime = this.recordTime
      this.setTimeoutfunc();
      // console.log('播放',this.audioSrc);
      // let audio = document.getElementById("audioVoice");
      // this.audio.paly();
      recorder.play();
      this.isVoice = 4;
      // audio.paly()
      // recorder.play();
      this.$emit("play");
    },

    // 暂停录音播放
    pausePlayRecorder() {
      clearTimeout(this.timeout);
      console.log('当前的录音时长', recorder.getPlayTime(3))
      let timeleft = recorder.getPlayTime(3) * 1000
      if (timeleft >= this.playTime) {
        this.stopPlayRecorder();
        return
      } else {
        this.playTime = this.playTime - timeleft
      }
      recorder.pausePlay();
      this.isVoice = 5;
      this.$emit("notplay");
    },

    // 恢复录音播放
    resumePlayRecorder() {
      console.log('恢复录音播放时长', this.playTime)
      this.setTimeoutfunc()
      recorder.resumePlay();
      this.isVoice = 4;
      this.$emit("play");
    },

    // 停止录音播放
    stopPlayRecorder() {
      this.playTime = this.recordTime
      console.log('停止后的录音时长', this.playTime)
      recorder.stopPlay();
      this.$emit("notplay");

      this.isVoice = 3;
    },

    // 销毁录音
    destroyRecorder() {
      this.$emit("notplay");
      recorder.destroy().then(() => {
        recorder = null;
        // 销毁之后重新添加
        recorder = new Recorder({
          sampleBits: 16, // 采样位数，支持 8 或 16，默认是16

          sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000

          numChannels: 1, // 声道，支持 1 或 2， 默认是1

          // compiling: false,(0.x版本中生效,1.x增加中) // 是否边录边转换，默认是false
        });
      });
    },

    /**
     * 获取录音文件
     * */
    getRecorder() {
      let duration = recorder.duration; //录音总时长
      console.log('获取最终的录音时长', duration)
      let fileSize = recorder.fileSize; //录音总大小
      //录音结束，获取取录音数据
      let PCMB = recorder.getPCMBlob(); //获取 PCM 数据
      let WAV = recorder.getWAVBlob(); //获取 WAV 数据
      let channel = recorder.getChannelData(); //获取左声道和右声道音频数据
      // console.log('当前的录音时长', recorder.getPlayTime(3))
      this.playTime = duration.toFixed(3) * 1000
      this.recordTime = this.playTime
      // console.log('当前的录音时长', this.playTime)
      return {
        duration,
        fileSize,
        PCMB,
        WAV,
        channel,
      };
    },

    /**
     * 下载录音文件
     * */

    //下载pcm
    downPCM() {
      //这里传参进去的时文件名
      recorder.downloadPCM("新文件");
    },

    //下载wav
    downWAV() {
      //这里传参进去的时文件名
      recorder.downloadWAV("新文件");
    },

    /**
     * 获取麦克风权限
     * */
    getPermission() {
      Recorder.getPermission().then(
        () => {
          this.$Message.success("获取权限成功");
        },
        (error) => {
          console.log(`${error.name} : ${error.message}`);
        }
      );
    },

    /**
     * 文件格式转换 wav-map3
     * */
    getMp3Data() {
      const mp3Blob = this.convertToMp3(recorder.getWAV());
      recorder.download(mp3Blob, "recorder", "mp3");
    },

    convertToMp3(wavDataView) {
      // 获取wav头信息
      const wav = lamejs.WavHeader.readHeader(wavDataView); // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
      console.log('wav',wav);
      const {channels, sampleRate} = wav;
      const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
      // 获取左右通道数据
      const result = recorder.getChannelData();
      const buffer = [];
      const leftData =
        result.left &&
        new Int16Array(result.left.buffer, 0, result.left.byteLength / 2);
      const rightData =
        result.right &&
        new Int16Array(result.right.buffer, 0, result.right.byteLength / 2);
      const remaining = leftData.length + (rightData ? rightData.length : 0);
      const maxSamples = 1152;
      for (let i = 0; i < remaining; i += maxSamples) {
        const left = leftData.subarray(i, i + maxSamples);

        let right = null;

        let mp3buf = null;

        if (channels === 2) {
          right = rightData.subarray(i, i + maxSamples);

          mp3buf = mp3enc.encodeBuffer(left, right);
        } else {
          mp3buf = mp3enc.encodeBuffer(left);
        }

        if (mp3buf.length > 0) {
          buffer.push(mp3buf);
        }
      }
      const enc = mp3enc.flush();
      if (enc.length > 0) {
        buffer.push(enc);
      }
      return new Blob(buffer, {
        type: "audio/mp3",
      });
    },
  },
};
</script>
<style>
#recordingBtn {
  width: 100px;
  height: 40px;
  margin: 0 auto;
  border: 1px solid rgba(246, 112, 75, 1);
  color: rgba(246, 112, 75, 1);
  font-size: 14px;
  background-color: transparent;
}
.record {
  text-align: center;
}
</style>
